/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

/* our styles */
import "stylesheets/application.scss";

/* turbo and stimulus */
import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();
const context = require.context("../src/controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));


/* js stuff */
import * as bootstrap from "bootstrap/dist/js/bootstrap";
global.bootstrap = bootstrap;

global.Routes = require("../src/routes.js.erb")
global.helpers = require("../src/helpers.js");

require("../src/fullcalendar-webcomponent.js");

Turbo.setConfirmMethod(helpers.confirmMethod);
Turbo.setProgressBarDelay(100);

Turbo.StreamActions.reload_calendar = function () {
  const id = this.templateContent.textContent;
  document
    .querySelector("thing-calendar[thing='" + id + "']")
    .refresh();
};

require("../src/pwa/companion.js");
import interact from "interactjs";
global.interact = interact;
