import rrulePlugin from '@fullcalendar/rrule';
import calendar_de from "./fullcalendar-locales-de.js";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrapPlugin from '@fullcalendar/bootstrap5';
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from '@fullcalendar/moment';

// fullcalendar fix for fontawesome
// https://github.com/fullcalendar/fullcalendar/issues/6721
bootstrapPlugin.themeClasses.bootstrap5.prototype.classes = {
  root: 'fc-theme-bootstrap5',
  tableCellShaded: 'fc-theme-bootstrap5-shaded',
  buttonGroup: 'btn-group',
  button: 'btn btn-sm btn-secondary',
  buttonActive: 'active',
  popover: 'popover',
  popoverHeader: 'popover-header',
  popoverContent: 'popover-body',
}
bootstrapPlugin.themeClasses.bootstrap5.prototype.baseIconClass = 'fa'
bootstrapPlugin.themeClasses.bootstrap5.prototype.iconOverridePrefix = 'fa-'
bootstrapPlugin.themeClasses.bootstrap5.prototype.iconClasses = {
    close: 'fa-times',
    prev: 'fa-chevron-left',
    next: 'fa-chevron-right',
    prevYear: 'fa-angle-double-left',
    nextYear: 'fa-angle-double-right',
}
bootstrapPlugin.themeClasses.bootstrap5.prototype.rtlIconClasses = {
    prev: 'fa-chevron-right',
    next: 'fa-chevron-left',
    prevYear: 'fa-angle-double-right',
    nextYear: 'fa-angle-double-left',
}

function thingConfig(calendar_component, thing_id, options) {
  if (options.initial_date === undefined)
    options.initial_date = (new Date()).toISOString();

  var defaults = {
    // touch
    longPressDelay: 100,
    eventLongPressDelay: 500,
    selectLongPressDelay: 200,

    editable: true, // not editable events have no url
    selectable: options.selectable,
    selectMirror: true,
    fixedMirrorParent: document.body,
    // selectMinDistance: 10, // does not work on mobile!
    selectMinDistance: 0,
    eventResizableFromStart: true,
    height: "100%",
    initialDate: options.initial_date,  // pass default date in case local date is wrong
    plugins: [ momentPlugin, dayGridPlugin , timeGridPlugin, multiMonthPlugin, listPlugin, interactionPlugin, bootstrapPlugin, rrulePlugin ],
    locales: [ calendar_de ],
    locale: 'de',
    timeZone: 'Europe/Berlin', // always use German timezone to avoid time shifting
                               // if 123dinge is used in other countries
    weekNumbers: true,
    scrollTime: '7:00',
    // timeFormat: "h",
    multiMonthMaxColumns: 1, // force a single column
    headerToolbar: {
        left: 'title',
        center: '',
        right: 'prev,today,next timeGridDay,timeGridWeek,dayGridMonth,listMonth'
    },
    themeSystem: 'bootstrap5',
    firstDay: 1,
    allDaySlot: true,
    titleFormat: "MMMM YYYY",
    slotLabelFormat: "HH",
    slotDuration: options.slot_duration,
    snapDuration: options.snap_duration,
    slotEventOverlap: false,
    scrollTimeReset: false,
    views: {
      timeGrid: {
	// options apply to timeGridWeek and timeGridDay views
	dayHeaderFormat: "dddd,ddd,D",  //{ xmonth: 'numeric', day: 'numeric', weekday: 'long' },
	dayHeaderContent: function(arg) {
	  let [day_long, day_short, day_num] = arg.text.split(",");
	  return { html: `<div class="fc-custom-date">${day_num}</div>
                          <div class="fc-custom-weekday-short d-block d-md-none">${day_short.replace(".", "")}</div>
                          <div class="fc-custom-weekday-long d-none d-md-block">${day_long}</div>` };
	},
      },
    },
    nowIndicator: true,
    initialView: "timeGridWeek",
    eventDisplay: "block",
    // businessHours: {
    //   // days of week. an array of zero-based day of week integers (0=Sunday)
    //   daysOfWeek: [ 1, 2, 3, 4, 5 ],

    //   startTime: '08:00', // a start time (10am in this example)
    //   endTime: '18:00', // an end time (6pm in this example)
    // },
    // dateClick: function(info) {
    //   alert('Clicked on: ' + info.dateStr);
    //   alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
    //   alert('Current view: ' + info.view.type);
    //   // change the day's background color just for fun
    //   info.dayEl.style.backgroundColor = 'red';
    // },
    eventDidMount: function(info) {
      if (info.isMirror) {
	// show time in badge above event
	calendar_component.querySelector(".fc-view-harness").classList.add("fc-event-mirror-dragging");
	// add z-index to rows, so mirror is on top
	// FIXME: does not work on mobile
	calendar_component.querySelectorAll(".fc-day").forEach((elem) => { elem.style.zIndex = 1; })
	info.el.closest(".fc-day").style.zIndex = 100;
      } else {
	addPopover(info);
      }
    },
    // https://stackoverflow.com/questions/60933726/fullcalendar-display-html-in-event-title
    // https://fullcalendar.io/docs/content-injection
    // FIXME: info.timeText is different for month/week views
    // https://github.com/fullcalendar/fullcalendar/issues/6133
    eventContent: function(info) {
      const inner =
	    '<div class="fc-event-main">' +
            '<div class="fc-event-main-frame">' +
            '<div class="fc-event-time">' + info.timeText + '</div>' +
            '<div class="fc-event-title-container"><div class="fc-event-title fc-sticky">' + info.event.title + '</div></div>' +
            '</div>' +
            '</div>';
      if (info.view.type == "listMonth")
	return { html: '<a class="link-underline link-underline-opacity-0" href="' + (info.event.url || "#") + '">' + inner + '</a>' };
      else
	return { html: inner };
    },
    eventClick: function(info) {
      info.jsEvent.preventDefault();
      setTimeout(hidePopovers, 100); // FIXME: set also z-index!
      if (info.event.url)
	Turbo.visit(info.event.url, { acceptsStreamResponse: true });
    },
    // move event action
    eventDragStart: function(info) {
      return false;
    },
    eventDrop: function(info) {
      hidePopovers();
      event_change(calendar_component, 'event', info);
    },
    eventResize: function(info) {
      hidePopovers();
      event_change(calendar_component, 'event', info);
    },
    // selectAllow: function(info) {
    //   console.log()
    //   return false;
    // },
    select: function(info) {
      let url = Routes.new_thing_event_path({
        "thing_id": thing_id,
        "event[start_at]": info.startStr,
        "event[stop_at]": info.endStr,
        "event[all_day]": info.allDay,
      })
      Turbo.visit(url, { acceptsStreamResponse: true });
    },
    viewDidMount: function(object) {
      const top = object.view.calendar.el.offsetTop;
      const footer = document.querySelector("body > footer").offsetHeight;
      // https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile
      object.view.calendar.setOption("height", "calc(100dvh - " + (top + footer + 0) + "px)");
    },
  };

  if (thing_id) {
    defaults["eventSources"] = [
      Routes.thing_events_path(thing_id, { format: "json" })
    ];
  }

  return defaults;
}

function hidePopovers() {
  document.querySelectorAll(".fc-event").forEach((elem) => {
    const popover = bootstrap.Popover.getOrCreateInstance(elem);
    popover.hide();
  });
}

// https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function event_change(calendar_component, type, info) {
  var startStr = calendar_component.getDateString(info.event.start);
  // FIXME: better way to do it?
  // see also fix_all_day in event class
  var endStr = null;
  // fix when moving from day to all_day
  if (!info.oldEvent.allDay && info.event.allDay) {
    endStr = startStr;
  // fix when moving from all_day to day
  } else if (info.oldEvent.allDay && !info.event.allDay) {
    endStr = calendar_component.getDateString(info.event.start.getTime() + (60 * 60 * 1 * 1000)); // 1 hour
  } else {
    endStr =
      info.event.allDay ?
      //calendar_component.getDateString(info.event.end) :
      calendar_component.getDateString(info.event.end.getTime() - (60 * 60 * 24 * 1000)) : // 24 hours
    calendar_component.getDateString(info.event.end);
  }
  var data = {};
  data[type] = { start_at: startStr, stop_at: endStr, all_day: info.event.allDay }

  fetch(info.event.url, {
    method: "PUT",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      Accept: "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrf_token,
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify(data),
  }).then(handleErrors)
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .catch(error => {
      // FIXME translate this code
      console.log(error);
      var msg = error;
      msg += "<br/>Der Kalender wurde neu geladen.";
      Promise.resolve(helpers.bootstrapAlert("Fehler beim Speichern!", msg, "danger"));
      calendar_component.refresh();
    })
}

function addPopover(info) {
  let ev = info.event;
  if (ev.extendedProps.popover_title === undefined
      || ev.extendedProps.popover_content === undefined) {
    // don't add popover if title or content is missing
    return;
  }
  const placeLeft = info.view.currentStart < ev.start && (ev.start.getDay() > 4 || ev.start.getDay() == 0);

  new bootstrap.Popover(info.el, {
    content: ev.extendedProps.popover_content,
    title: ev.extendedProps.popover_title,
    html: true,
    delay: { show: 100, hide: 0 },
    trigger: "hover",
    sanitize: false, // FIXME: add tests, sanitize on server
    container: "body",
    placement: ev.allDay ? "bottom" : (placeLeft ? "left" : "right"),
    boundary: "window",
  });
}

// module.exports = {
//   thingConfig
// };
export default thingConfig;
