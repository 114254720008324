import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // FIXME: no backdrop...
    this.modal = new bootstrap.Modal(this.element, {
      //backdrop: false,
    })
    // this.element.addEventListener('show.bs.modal', () => {
    //   const element = document.createElement("div");
    //   element.className = "modal-backdrop fade show";
    //   document.body.append(element);
    //   element.addEventListener("click", () => {
    // 	console.log("xxxxclick");
    // 	this.modal.hide();
    //   });
    // })
    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove();
    })
    document.addEventListener('turbo:submit-end', this.handleSubmit)

    this.modal.show();
  }

  disconnect() {
    console.log("disconnect")
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  // is not handleSubmit(e) {
  // must be defined on document!
  handleSubmit = (event) => {
    if (event.target.id && !this.element.querySelector("form#" + event.target.id))
      return; // not our form

    if (event.target.dataset.skipModalClose)
      return; // bo not close modal on this action

    if (event.detail.success) {
      this.close()
    }
  }

  handleKeyup(event) {
    if (event.code == "Escape") {
      this.close()
    }
  }

  close() {
    this.modal.hide();
    return false;
  }
}
