import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "allDay", "timeForm", "dateForm" ]

  connect() {
    this.update()
  }

  update() {
    let show;
    let hide;
    if (this.allDayTarget.checked) {
      show = this.dateFormTarget;
      hide = this.timeFormTarget;
    } else {
      show = this.timeFormTarget;
      hide = this.dateFormTarget;
    }
    show.classList.remove("d-none");
    show.querySelectorAll("input").forEach((elem) => { elem.disabled = false });
    hide.classList.add("d-none");
    hide.querySelectorAll("input").forEach((elem) => { elem.disabled = true });
  }

}
