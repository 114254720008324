import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const footerHeight = document.querySelector("body > footer").offsetHeight;
    this.element.style.marginBottom = footerHeight + "px";
  }

}
