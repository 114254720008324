import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.removeAttribute('hidden');
    this.element.classList.add('animate__faster',
			       'animate__animated',
			       'animate__slideInDown');

    setTimeout(() => { this.close(); }, 5000);
  }

  close() {
    this.element.classList.add('animate__slideOutUp');

    this.element.addEventListener('animationend', () => {
      this.element.remove();
    });
  }
}
