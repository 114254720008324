function confirmMethod(message, _element, _submitter) {
  return new Promise((resolve, reject) => { bootstrapConfirm(message, resolve) });
}

// FIXME: translate
function bootstrapConfirm(msg, resolve) {
  document.activeElement.blur();
  const element = document.createElement('div')
  element.id = "modal-confirm"
  element.className = "modal fade"
  element.innerHTML = `
    <div class="modal-dialog modal-shadow modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body">
          <p>${msg}</p>
        </div>
        <div class="modal-footer">
          <button id="modal-btn-abort" type="button" class="btn btn-secondary">Abbrechen</button>
          <button id="modal-btn-accept" type="button" class="btn btn-primary">OK</button>
        </div>
      </div>
    </div>
  `
  const modal = new bootstrap.Modal(element, {
    keyboard: false,
    backdrop: "static",
  })
  modal.show();

  element.addEventListener('hidden.bs.modal', () => {
    element.remove();
    document.querySelectorAll("#modal-container .modal").forEach((elem) => {
      elem.style.removeProperty('z-index');
    });
  });

  document.querySelectorAll("#modal-container .modal").forEach((elem) => {
    elem.style.zIndex = "1040";
  });

  document.addEventListener('click', response)
  document.addEventListener('keydown', response)

  function response(event) {
    let bool = false

    if (event.type == "click" && event.target.id == "modal-btn-abort") bool = false
    else if (event.type == "click" && event.target.id == "modal-btn-accept") bool = true
    else if (event.code == "Escape") bool = false
    else if (event.code == "Enter" && event.target.id == "modal-btn-abort") bool = false
    else if (event.code == "Enter") bool = true
    else {
      return
    }

    document.removeEventListener('click', response)
    document.removeEventListener('keydown', response)
    modal.hide()
    resolve(bool)
  }
}

async function bootstrapAlert(title, msg, color) {
  const element = document.createElement('div')
  element.id = "modal-alert"
  element.className = "modal fade"
  element.innerHTML = `
    <div class="modal-dialog modal-shadow modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <strong class="text-${color}">${title}</strong>
        </div>
        <div class="modal-body">
          <p>${msg}</p>
        </div>
        <div class="modal-footer">
          <button id="modal-btn-accept" type="button" class="btn btn-primary">Schließen</button>
        </div>
      </div>
    </div>
  `
  const modal = new bootstrap.Modal(element, {
    // FIXME: enable keyboard.
    // bug: when two modals are open, the first closes...
    keyboard: false,
    backdrop: "static",
  })
  modal.show()
  element.addEventListener('hidden.bs.modal', () => {
    element.remove();
  })

  document.querySelectorAll("#modal-container .modal").forEach((elem) => {
    elem.style.zIndex = "1040";
  });

  return new Promise((resolve, reject) => {
    document.body.addEventListener('click', response)

    function response(e) {
      document.body.removeEventListener('click', response)
      modal.hide()
      document.querySelectorAll("#modal-container .modal").forEach((elem) => {
	elem.style.removeProperty('z-index');
      });
      resolve(bool)
    }
  })
}

module.exports = {
  confirmMethod, bootstrapAlert
}
