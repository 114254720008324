import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.mainContent = document.getElementById("main-content");
    this.sidebarContent = document.getElementById("sidebar-content");
    this.mainVisible = true;
  }

  click(event) {
    event.preventDefault();
    if (this.mainVisible) {
      this.mainContent.classList.add("d-none")
      this.sidebarContent.classList.remove("d-none")
      this.mainVisible = false;
    } else {
      this.mainContent.classList.remove("d-none")
      this.sidebarContent.classList.add("d-none")
      this.mainVisible = true;
    }
  }

}
