import { Controller } from "@hotwired/stimulus"
import { RRule } from 'rrule'
import moment from 'moment';
moment.locale('de');

// FIXME: the rrule translation is not really great

const translation_plural = {
  every: 'Alle',
}

const translation = {
  every: '',
  until: 'bis',
  day: 'Jeden Tag',
  days: 'Tage',
  week: 'Jede Woche',
  weeks: 'Wochen',
  on: 'am',
  in: 'in',
  'on the': 'auf dem',
  for: 'für',
  and: 'und',
  or: 'oder',
  at: 'bei',
  last: 'zuletzt',
  '(~ approximate)': '(~ approximativ)',
  times: 'Mal',
  time: 'Zeit',
  minutes: 'Minuten',
  hours: 'Stunden',
  weekdays: 'Wochentage',
  weekday: 'Wochentag',
  months: 'Monate',
  month: 'Jeden Monat',
  years: 'Jahre',
  year: 'jedes Jahr'
};

const language = {
  dayNames: moment.weekdays(),
  monthNames: moment.months()
};

const getText = (id) => {
  return translation[id];
};

const getText2 = (id) => {
  return { ...translation, ...translation_plural }[id];
};

const dateFormat = (year, month, day) =>
    moment()
      .date(day)
      .year(year)
      .month(month)
      .format("LL");


export default class extends Controller {

  connect() {
    const rrule = RRule.fromString(this.element.dataset.rrule);
    if (rrule.options.interval == 1)
      this.element.innerHTML = rrule.toText(getText, language, dateFormat);
    else
      this.element.innerHTML = rrule.toText(getText2, language, dateFormat);
  }

}
