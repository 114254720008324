import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.icon = document.createElement("i");
    this.icon.className = "fas fa-eye password-visibility-icon text-secondary";
    this.element.parentNode.insertBefore(this.icon, this.element);

    this.icon.addEventListener("click", (event) => { this.click(event) });
  }

  click(event) {
    const type = this.element.getAttribute("type");
    if (type === "password") {
      this.element.setAttribute("type", "text");
      this.icon.classList.remove("text-secondary");
      this.icon.classList.add("text-success");
    } else {
      this.element.setAttribute("type", "password");
      this.icon.classList.remove("text-success");
      this.icon.classList.add("text-secondary");
    }
    this.element.focus();
  }

}
