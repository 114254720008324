import { Controller } from "@hotwired/stimulus"
import { RRule, RRuleSet, Frequency, ALL_WEEKDAYS, rrulestr, datetime } from 'rrule'

export default class extends Controller {
  static targets = [ "rrule", "frequency", "interval",
		     "byweekday", "bysetpos", "bymonth",
		     "yearlyByday", "monthlyByday",
		     "weeklyByweekday",
		     "endContainer", "end", "until", "count",
		     "rruleForm"
//		     "days", "words",
		   ]
  static values = {
    dtstart: String,
    frequency: String,
    end: String,
    count:  { type: Number, default: 1 },
    until: String,
    option: String,
    interval: Number,
    bysetpos: { type: Number, default: 1 },
    bymonth: { type: Number, default: 1 },
    byweekday: { type: Number, default: 1 },
  }

  intervalValueChanged() {
    this.updateResults()
  }

  frequencyValueChanged() {
    this.updateResults()
  }

  endValueChanged() {
    this.updateResults()
  }

  countValueChanged() {
    this.updateResults()
  }

  untilValueChanged() {
    this.updateResults()
  }

  byweekdayValueChanged() {
    this.updateResults()
  }

  bysetposValueChanged() {
    this.updateResults()
  }

  bymonthValueChanged() {
    this.updateResults()
  }

  optionValueChanged() {
    this.updateResults()
  }

  updateResults() {
    if (!this.initialized) return;

    if (this.frequencyValue == "NONE") {
      this.rruleTarget.value = ""
      // this.daysTarget.innerHTML = ""
      return;
    }

    const options = {
      freq:    RRule[this.frequencyValue],
      dtstart: this.getDtstart(),
    }

    if (this.intervalValue)
      options["interval"] = this.intervalValue;

    if (this.byweekdayArray.length > 0)
      options["byweekday"] = this.byweekdayArray.sort((a,b) => a.weekday - b.weekday);

    switch (this.optionValue) {
      case "YEARLY-BY-DAY": {
	options["bysetpos"] = this.bysetposValue;
	options["bymonth"] = this.bymonthValue;
	break;
      }
      case "MONTHLY-BY-DAY": {
	options["bysetpos"] = this.bysetposValue;
	break;
      }
    }

    switch (this.endValue) {
      case "COUNT": {
	if (this.countValue) options["count"] = this.countValue;
	break;
      }
      case "UNTIL": {
	if (this.untilValue) options["until"] = new Date(this.untilValue);
	break;
      }
    }

    const rule = new RRule(options)
    this.rruleTarget.value = rule.toString();
    // this.wordsTarget.innerHTML = rule.toText();
    // this.daysTarget.innerHTML = rule.all((date, i) => { return i < 10 }).join("<br>");
  }

  connect() {
    this.initialized = false;

    this.byweekdayArray = [];
    this.untilValue = this.dtstartValue;

    if (this.rruleTarget.value !== "") {
      this.initial_rrule = RRule.fromString(this.rruleTarget.value);
      this.setFrequencyValue(Frequency[this.initial_rrule.options.freq])
      this.setIntervalValue(this.initial_rrule.options.interval);

      switch (this.frequencyValue) {
        case "WEEKLY": {
	  if (this.initial_rrule.options.byweekday) {
	    this.initial_rrule.options.byweekday.forEach((day) => {
	      this.weeklyByweekdayTargets[day].checked = true;
	    })
	    this.byweekdayArray = this.initial_rrule.options.byweekday;
	    this.byweekdayValue += 1;
	  }
	  break;
	}
        case "MONTHLY": {
	  if (this.initial_rrule.options.byweekday) {
	    this.byweekdayTarget.value = ALL_WEEKDAYS[this.initial_rrule.options.byweekday[0]];
	    this.byweekdayArray = this.initial_rrule.options.byweekday;
	    this.byweekdayValue += 1;

	    this.setBysetposValue(this.initial_rrule.options.bysetpos[0]);
	    this.monthlyBydayTarget.checked = true;
	    this.optionValue = this.monthlyBydayTarget.value;
	  }
	  break;
	}
        case "YEARLY": {
	  if (this.initial_rrule.options.byweekday) {
	    this.byweekdayTarget.value = ALL_WEEKDAYS[this.initial_rrule.options.byweekday[0]];
	    this.byweekdayArray = this.initial_rrule.options.byweekday;
	    this.byweekdayValue += 1;

	    this.setBysetposValue(this.initial_rrule.options.bysetpos[0]);
	    this.setBymonthValue(this.initial_rrule.options.bymonth[0]);
	    this.yearlyBydayTarget.checked = true;
	    this.optionValue = this.yearlyBydayTarget.value;
	  }
	  break;
	}
      }

      if (this.initial_rrule.options.until) {
	this.setUntilValue(this.initial_rrule.options.until.toISOString().substring(0,10));
	this.setEndValue("UNTIL");
      } else if (this.initial_rrule.options.count) {
	this.setCountValue(this.initial_rrule.options.count);
	this.setEndValue("COUNT");
      }
    }

    this.initialized = true;
  }

  getDtstart() {
    return new Date(this.dtstartValue)
  }

  setBysetpos(event) {
    this.setBysetposValue(event.target.value);
  }

  setBysetposValue(value) {
    this.bysetposTarget.value = value;
    this.bysetposValue = value;
  }

  setBymonth(event) {
    this.setBymonthValue(event.target.value);
  }

  setBymonthValue(value) {
    this.bymonthTarget.value = value;
    this.bymonthValue = value;
  }

  setByweekday(event) {
    const val = RRule[event.target.value]
    this.byweekdayArray = [val]
    // increment the marker so change is noticed
    this.byweekdayValue += 1;
  }

  setByweekdayMultiple(event) {
    const val = RRule[event.target.value]
    if (event.target.checked) {
      this.byweekdayArray.push(val)
    } else
      this.byweekdayArray.splice(this.byweekdayArray.indexOf(val),1)
    // increment the marker so change is noticed
    this.byweekdayValue += 1;
  }

  setInterval(event) {
    this.setIntervalValue(event.target.value);
  }

  setIntervalValue(value) {
    this.intervalTarget.value = value;
    this.intervalValue = value;
  }

  setUntil(event) {
    this.setUntilValue(event.target.value);
  }

  setUntilValue(value) {
    this.untilTarget.value = value;
    this.untilValue = value;
  }

  setCount(event) {
    this.setCountValue(event.target.value);
  }

  setCountValue(value) {
    this.countTarget.value = value;
    this.countValue = value;
  }

  setOption(event) {
    const selected = event.target.value;
    if (event.target.checked) {
      this.optionValue = selected;
      switch (selected) {
        case "YEARLY-BY-DAY": {
	  this.bysetposValue  = this.bysetposTarget.value
	  this.byweekdayArray = [RRule[this.byweekdayTarget.value]]
	  this.bymonthValue   = this.bymonthTarget.value
	  break;
	}
        case "MONTHLY-BY-DAY": {
	  this.bysetposValue  = this.bysetposTarget.value
	  this.byweekdayArray = [RRule[this.byweekdayTarget.value]]
	  break;
	}
      }
    } else {
      this.resetOption();
    }
  }

  setEnd(event) {
    this.setEndValue(event.target.value);
  }

  setEndValue(selected) {
    this.endTarget.value = selected;
    this.endContainerTarget.className = "rrule-end-" + selected.toLowerCase();

    this.endValue = selected;
  }

  setFrequency(event) {
    this.setFrequencyValue(event.target.value);
  }

  setFrequencyValue(selected) {
    this.frequencyTarget.value = selected;
    this.element.className = "rrule-" + selected.toLowerCase();

    if (selected == "NONE") { this.setEndValue("NONE") }
    this.resetOption();

    this.frequencyValue = selected;
  }

  toggleVisibility(event) {
    if (event.target.checked) {
      this.rruleFormTarget.classList.remove("d-none");
      this.updateResults();
    } else {
      this.rruleFormTarget.classList.add("d-none");
      this.rruleTarget.value = "";
    }
  }

  hideAll(query) {
    this.element.querySelectorAll(query).forEach((elem) => {
      elem.style.display = "none";
    });
  }

  hide(query) {
    this.element.querySelector(query).style.display = "none";
  }

  show(query) {
    this.element.querySelector(query).style.display = "block";
  }

  resetOption() {
    this.optionValue = "NONE";
    this.byweekdayArray = [];
  }

  check(name) {
    this[name + "Value"].checked = true;
  }
}
