import { LitElement, html } from 'lit-element';

import { Calendar } from '@fullcalendar/core';
import thingConfig from "./fullcalendar-config.js";

class FullcalendarWebcomponent extends LitElement {
  //@internalProperty({ type: Object })
  calendar = null;

  // Declare observed properties
  static get properties() {
    return {
      reload: {},
    }
  }

  render() {
    return html`
    <div></div>
    `;
  }

  calendarElement() {
    return this.querySelector(':scope > div');
  }

  disposePopovers() {
    // Deactivate popover before reload (otherwise popover may get stuck and will never hide)
    // FIXME: duplicate in config
    document.querySelectorAll(".fc-event").forEach((elem) => {
      const popover = bootstrap.Popover.getInstance(elem);
      popover.dispose();
    });
  }

  getDateString(dateObj) {
    // Calender will format date object without timezone info
    // because FullCalender creates Date objects with fake UTC
    // even if dates are sent with timezone information
    // (use of momentPlugin could fix this)
    return this.calendar.formatIso(dateObj);
  }

  refresh() {
    this.disposePopovers();
    var cal = this.calendar;
    cal.batchRendering(function() {
      var events = cal.getEvents();
      events.forEach(function(ev) {
        // disallow moving / resizing while refetching
        ev.setProp("editable", false);
      });
      cal.refetchEvents();
    });
  }

  redraw() {
    this.calendar.render();
  }

  updated(changedProperties) {
    changedProperties.forEach((_old_value, prop_name) => {
      if (prop_name == "reload") {
        this.calendar.render();
      }
    });
  }

  firstUpdated() {
    const node = this;
    const thing_id = this.getAttribute('thing');
    const options = {
      initial_date:  this.getAttribute('date'),
      selectable:    this.getAttribute('selectable'),
      snap_duration: this.getAttribute('snap_duration'),
      slot_duration: this.getAttribute('slot_duration'),
    };

    const config = thingConfig(node, thing_id, options);
    this.calendar = new Calendar(this.calendarElement(), config);
    this.calendar.render();

    // FIXME: refactor this
    const harness = node.querySelector(".fc-view-harness");
    harness.style.overflow = "hidden";
    let calendar = node.querySelector(".fc-view-harness .fc-view");
    let calendar_next = null;
    let moved = 0;
    const position = { x: 0, y: 0 }
    const next_pos = calendar.offsetWidth;
    const prev_pos = -calendar.offsetWidth;
    let scroll_top = null;

    // FIMXE: whole calendar can not be draggable on mobile!?
    // FIXME: broken on alternative browsers?
    interact(".fc-col-header").draggable({
      inertia: false,
      modifiers: [],
      startAxis: 'x',
      lockAxis: 'x',
      listeners: {
	start: (event) => {
	  // this.calendar.setOption("selectable", false);
	  // this.calendar.setOption("selectAllow", (info) => { return false; });
	  // this.calendar.unselect();
	  calendar = node.querySelector(".fc-view-harness .fc-view");
	  scroll_top = calendar.querySelector(".fc-scroller-liquid-absolute").scrollTop;
	  calendar_next = calendar.cloneNode(true);
	  calendar.style.transform = `translate(${next_pos}px, 0px)`;
	  calendar_next.style.transform = `translate(0px, 0px)`;
	  harness.appendChild(calendar_next);
	  calendar_next.querySelector(".fc-scroller-liquid-absolute").scrollTop = scroll_top;
	},
	move: (event) => {
	  position.x += event.dx;
	  if (position.x < prev_pos) position.x = prev_pos;
	  else if (position.x > next_pos) position.x = next_pos;

	  // disabled because swipe is on table header
	  // if (Math.abs(position.x) < 20) return;

	  if (position.x < 0 && moved != 1) {
	    this.calendar.next();
	    if (moved == -1) this.calendar.next();
	    moved = 1;
	  }
	  if (position.x > 0 && moved != -1) {
	    this.calendar.prev();
	    if (moved == 1) this.calendar.prev();
	    moved = -1;
	  }
	  if (position.x < 0) {
	    calendar.style.transform = `translate(${next_pos + position.x}px, 0px)`;
	    calendar_next.style.transform = `translate(${position.x}px, 0px)`;
	  }
	  if (position.x > 0) {
	    calendar.style.transform = `translate(${prev_pos + position.x}px, 0px)`;
	    calendar_next.style.transform = `translate(${position.x}px, 0px)`;
	  }
	},
      },
      onend: (event) => {
	// this.calendar.setOption("selectable", options.selectable);
	// this.calendar.setOption("selectAllow", null);
	calendar_next.style.transition = `transform .1s ease`;
	calendar.style.transition = `transform .1s ease`;
	if ((event.swipe && event.swipe.left) || position.x <= 0 && position.x <= prev_pos/2) {
	  calendar.style.transform = `translate(0px, 0px)`;
	  calendar_next.style.transform = `translate(${prev_pos}px, 0px)`;
	  setTimeout(() => {
	    calendar.style.transition = ``;
	    calendar.style.transform = `translate(0px, 0px)`;
	    calendar_next.remove();
	  }, 100);
	} else if ((event.swipe && event.swipe.right) || position.x > 0 && position.x >= next_pos/2) {
	  calendar.style.transform = `translate(0px, 0px)`;
	  calendar_next.style.transform = `translate(${next_pos}px, 0px)`;
	  setTimeout(() => {
	    calendar.style.transition = ``;
	    calendar.style.transform = `translate(0px, 0px)`;
	    calendar_next.remove();
	  }, 100);
	} else if (position.x <= 0 && position.x > prev_pos/2) {
	  calendar_next.style.transform = `translate(0px, 0px)`;
	  calendar.style.transform = `translate(${next_pos}px, 0px)`;
	  setTimeout(() => {
	    calendar.style.transition = ``;
	    calendar.style.transform = `translate(0px, 0px)`;
	    calendar.remove();
	    calendar_next.remove();
	    this.calendar.prev();
	    harness.querySelector(".fc-scroller-liquid-absolute").scrollTop = scroll_top;
	  }, 100);
	} else if (position.x > 0 && position.x < next_pos/2) {
	  calendar_next.style.transform = `translate(0px, 0px)`;
	  calendar.style.transform = `translate(${prev_pos}px, 0px)`;
	  setTimeout(() => {
	    calendar.style.transition = ``;
	    calendar.style.transform = `translate(0px, 0px)`;
	    calendar.remove();
	    calendar_next.remove();
	    this.calendar.next();
	    harness.querySelector(".fc-scroller-liquid-absolute").scrollTop = scroll_top;
	  }, 100);
	}
	position.x = 0;
	moved = 0;
      }
    })
  }

  createRenderRoot() {
    /**
     * Render template in light DOM. Note that shadow DOM features like
     * encapsulated CSS are unavailable.
     */
    return this;
  }

  fireDataChanged() {
    this.dispatchEvent(new CustomEvent("data-changed"));
  }
}

customElements.define('thing-calendar', FullcalendarWebcomponent);
