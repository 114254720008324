import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "startAt", "stopAt" ]

  connect() {
    this.count = 0;
    this.updateForStep(this.startAtTarget);
    this.updateForStep(this.stopAtTarget);
  }

  blur(event) {
    this.updateForStep(event.target);
  }

  updateForStep(target) {
    this.step = target.step / 60;
    this.value = this.getDate(target.value);
    this.roundTime(this.value);
    target.value = this.isoTime(this.value);
  }

  keyDown(event) {
    this.step = event.target.step / 60;
    this.value = this.getDate(event.target.value);
  }

  keyUp(event) {
    const new_value = this.getDate(event.target.value);

    // minutes not changed
    if (this.value.getMinutes() == new_value.getMinutes()) return;

    switch (event.key) {
    case "ArrowUp":
      this.addStep(this.value);
      break;
    case "ArrowDown":
      this.subStep(this.value);
      break;
    default:
      this.count += 1;
      if (this.count == 1) return;
      if (this.count == 2) {
	this.value = this.getDate(event.target.value);
      }
    }
    this.count = 0;
    this.roundTime(this.value);
    event.target.value = this.isoTime(this.value);
  }

  getDate(str) {
    return new Date(str + "+00:00");
  }

  isoTime(time) {
    return time.toISOString().substring(0,16);
  }

  roundTime(time) {
    time.setMinutes(Math.imul(Math.floor(time.getMinutes() / this.step), this.step));
  }

  addStep(time) {
    time.setMinutes(time.getMinutes() + this.step);
  }

  subStep(time) {
    time.setMinutes(time.getMinutes() - this.step);
  }

}
